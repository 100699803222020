import { Component, Input } from '@angular/core';
import { UserService } from '@features/auth';
import { select, Store } from '@ngrx/store';
import { ConnectedContractTypeDefinition } from '@connected/data-access/fleet-service';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContractTypePipe } from '../shared/pipes/contract-type/contract-type';
import { OperatorMonitorState } from '../shared/store';
import { AlertsActions, AlertsSelectors } from '../shared/store/alerts';
import { HeaderSelectors } from '../shared/store/header';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ProductTypes } from '@config';
import {
  SUPPORTS_MCL,
  SUPPORTS_SYSTEM_COMMUNICATION,
} from '../shared/feature-mapping.config';

@Component({
  selector: 'opm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: false,
})
export class SidebarComponent {
  @Input() isIos: boolean;

  activeAlertsCount$: Observable<number>;
  equipmentNumber: string;
  hasEquipmentAssigned$: Observable<boolean>;
  hasTelematic: boolean;
  isCrane: boolean;
  hasMCL: boolean;
  isCommunicationEnabled: boolean;
  contractType: string;
  contractTypePipe = new ContractTypePipe();
  connectedContractType = ConnectedContractTypeDefinition;

  readonly isMCLEnabledEquipmemnts = SUPPORTS_MCL;
  readonly isCommunicationEnabledEquipmemnts = SUPPORTS_SYSTEM_COMMUNICATION;

  isNative = Capacitor.isNativePlatform();

  constructor(
    public userService: UserService,
    private store: Store<OperatorMonitorState>,
    private _router: Router,
  ) {
    // get active alerts for badge
    this.activeAlertsCount$ = this.store.select(AlertsSelectors.getActiveCount);

    // watch for equipment selection to load alerts accordingly
    this.store
      .pipe(select(HeaderSelectors.getSelectedEquipment), filterTruthy())
      .subscribe((equipment) => {
        if (equipment.equipment_number) {
          this.equipmentNumber = equipment.equipment_number;
          this.store.dispatch(
            AlertsActions.LoadDetails({
              payload: equipment.equipment_number,
            }),
          );
        }
        this.hasTelematic = equipment.has_telematics;
        this.hasMCL = this.isMCLEnabledEquipmemnts.includes(
          equipment.equipment_type_icon,
        );
        this.isCrane =
          equipment.equipment_type_icon === ProductTypes.LoaderCranes;

        this.isCommunicationEnabled =
          this.isCommunicationEnabledEquipmemnts.includes(
            equipment.equipment_type_icon,
          );

        this.contractType = this.contractTypePipe.transform(equipment);

        // alerts polling
        timer(
          Number(environment.alerts_polling_interval_seconds) * 1000,
        ).subscribe(() => {
          if (this.equipmentNumber) {
            this.store.dispatch(
              AlertsActions.LoadDetails({
                payload: this.equipmentNumber,
              }),
            );
          }
        });

        this.hasEquipmentAssigned$ = this.store.select(
          HeaderSelectors.getHasEquipmentAssigned,
        );
      });
  }

  reload() {
    window.location.reload();
  }

  logout() {
    if (this.isNative) this.userService.logoutLocally();
    else this.userService.logout();
    this._router.navigate(['./landing']);
  }
}
