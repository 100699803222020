import { ProductTypes } from '@config';

export const mapPPI2PPG = <T>(item: T, prop: keyof T): T => {
  if (item && item[prop] === ProductTypes.AccessPlatformPPISmart) {
    return {
      ...item,
      [prop]: ProductTypes.AccessPlatforms,
    };
  }
  return item;
};
