import { CommonModule, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { APPS, ProductTypes } from '@config';
import { UserService } from '@features/auth';
import { getBrowserLanguage } from '@features/translation';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DriverEquipmentResponse } from '@connected/data-access/fleet-service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { NavbarItem } from '../shared/model/navbar-item.model';
import { PpgViewEnum } from '../shared/model/ppg-view.model';
import { OperatorMonitorState } from '../shared/store';
import { AlertsActions, AlertsSelectors } from '../shared/store/alerts';
import { HeaderActions, HeaderSelectors } from '../shared/store/header';
import { SettingsActions, SettingsSelectors } from '../shared/store/settings';
import { LanguageDialogComponent } from './language-dialog/language-dialog.component';
import { filterTruthy } from '@shared-lib/rxjs';
import { ActiveAlertsInfoComponent } from '../dashboard/active-alerts-info/active-alerts-info.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { MatSelectModule } from '@angular/material/select';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorInfoComponent } from '../shared/components/error-info/error-info.component';
import {
  DashboardActions,
  DashboardSelectors,
} from '../shared/store/dashboard';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { SignalRService } from '@connected/data-access/fleet-service/signal-r';
import { FeatureFlagSelectors } from '@connected/shared/store';

@Component({
  selector: 'opm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    DsEquipmentIconModule,
    DsSpacingModule,
    DsLoadingModule,
    GoogleAnalytics4DirectiveModule,
    PdLetDirectiveModule,
    ActiveAlertsInfoComponent,
    ErrorInfoComponent,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() navItems: NavbarItem[];
  @Input() isOffline: boolean;
  @Output() menuClick = new EventEmitter();
  @Output() offlineClick = new EventEmitter();
  @Output() noEquipmentClick = new EventEmitter();

  equpimentSelection: UntypedFormControl = new UntypedFormControl();
  equipmentList$: Observable<(DriverEquipmentResponse | null)[] | undefined>;
  isEquipmentListLoading$: Observable<boolean>;
  hasEquipmentListFailed$: Observable<boolean>;
  activeAlertsCount$: Observable<number>;
  hasPpgCommunicationActive: boolean;
  userLanguage: string;
  ppgViewEnum = PpgViewEnum;
  ppgView = Object.values(PpgViewEnum).filter(
    (view) => typeof view === 'string',
  );
  activeView = PpgViewEnum.carrier;

  statusCodeView = [
    {
      path: 'codes/active',
      label: 'palcode.nav_active_errors',
      availableForNonTelematicEquipment: false,
    },
    {
      path: 'codes/history',
      label: 'palcode.nav_errors',
      availableForNonTelematicEquipment: false,
    },
    {
      path: 'codes/finder',
      label: 'palcode.nav_search',
      availableForNonTelematicEquipment: true,
    },
  ];
  hasTelematics = false;
  isInDiagnosticMode = false;

  private destroy$ = new Subject<void>();
  private _isSignalREnabled = false;

  constructor(
    private store: Store<OperatorMonitorState>,
    public userService: UserService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    public location: Location,
    private _signalRService: SignalRService,
  ) {
    this.userLanguage =
      getBrowserLanguage(this.translateService).toLocaleLowerCase() || 'en';
    this.store.dispatch(
      AlertsActions.SetLanguage({ payload: this.userLanguage }),
    );
    this.activeAlertsCount$ = this.store.select(AlertsSelectors.getActiveCount);
    this.store
      .select(DashboardSelectors.getEquipmentDetail)
      .pipe(takeUntilDestroyed())
      .subscribe((equipment) => {
        this.isInDiagnosticMode = !!equipment?.programming_interface_active;
      });
    if (
      this.userLanguage &&
      this.userLanguage !== this.translateService.currentLang &&
      !userService?.isAuthorized
    ) {
      this.translateService.resetLang(this.userLanguage);
      this.translateService.use(this.userLanguage);
    }
    this.equpimentSelection.valueChanges.subscribe(
      (equipment: DriverEquipmentResponse) => {
        if (equipment) {
          this.store.dispatch(
            HeaderActions.SelectEquipment({
              payload: equipment,
            }),
          );
          // set as default equipment
          this.store.dispatch(
            SettingsActions.SetDefaultEquipment({
              payload: equipment.equipment_number || '',
            }),
          );
          this.store.dispatch(
            DashboardActions.LoadEquipmentDetail({
              payload: equipment.equipment_number || '',
            }),
          );
          this.store
            .select(FeatureFlagSelectors.isSignalREnabled)
            .subscribe((flag) => {
              this._isSignalREnabled = flag;
              if (flag) {
                this._signalRService.startEquipmentConnection(
                  equipment.equipment_number || '',
                );
                this._signalRService.operatingMinutesListener();
              }
            });
        }
      },
    );

    this.equipmentList$ = this.store
      .select(HeaderSelectors.getEquipmentList)
      .pipe(
        withLatestFrom(
          this.store.select(SettingsSelectors.getDefaultEquipment),
        ),
        map(([list, defaultEquipmentNumber]) => {
          this.selectDefaultEquipment(defaultEquipmentNumber, list);
          return list;
        }),
      );

    this.store
      .select(HeaderSelectors.getSelectedEquipment)
      .subscribe(
        (equipment) =>
          (this.hasPpgCommunicationActive =
            equipment?.equipment_type_icon === ProductTypes.AccessPlatforms &&
            equipment.has_telematics),
      );

    this.isEquipmentListLoading$ = this.store.select(
      HeaderSelectors.getIsEquipmentListLoading,
    );
    this.hasEquipmentListFailed$ = this.store.select(
      HeaderSelectors.getHasEquipmentListFailed,
    );

    this.store
      .select(HeaderSelectors.getSelectedEquipment)
      .pipe(filterTruthy(), takeUntil(this.destroy$))
      .subscribe((equipment) => {
        this.hasTelematics = equipment.has_telematics;
      });
  }

  selectDefaultEquipment(
    defaultNumber: string,
    list?: (DriverEquipmentResponse | null)[],
  ) {
    // select default
    if (list && list.length > 0) {
      let defaultEquipment = list.find(
        (x) => x && x.equipment_number === defaultNumber,
      );
      // if default is not in list
      if (!defaultEquipment) {
        // take the first
        defaultEquipment = list[0];

        if (defaultEquipment) {
          // save as feature default equipment
          this.store.dispatch(
            SettingsActions.SetDefaultEquipment({
              payload: defaultEquipment.equipment_number || '',
            }),
          );

          if (this._isSignalREnabled) {
            this._signalRService.startEquipmentConnection(
              defaultEquipment.equipment_number || '',
            );
            this._signalRService.operatingMinutesListener();
          }
        }
      }
      this.equpimentSelection.setValue(defaultEquipment);
    }
  }

  ngOnInit() {
    this.userService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && this.hasOperatorRole()) {
          this.store.dispatch(HeaderActions.LoadEquipmentList());
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    if (this.hasPpgCommunicationActive)
      this.store.dispatch(
        HeaderActions.SetPpgView({ payload: PpgViewEnum.carrier }),
      );
  }

  openLanguageDialog() {
    const dialogRef = this.dialog.open(LanguageDialogComponent, {
      width: '500px',
      data: this.userLanguage,
    });
    dialogRef.afterClosed().subscribe(async () => {
      const newLanguage = dialogRef.componentInstance.userLanguage;
      if (this.userLanguage !== newLanguage) {
        this.userLanguage = newLanguage;
        this.store.dispatch(
          AlertsActions.SetLanguage({ payload: this.userLanguage }),
        );
      }
    });
  }

  setPpgView(view: PpgViewEnum) {
    this.store.dispatch(HeaderActions.SetPpgView({ payload: view }));
  }

  hasOperatorRole(): boolean {
    return this.userService.hasOneRole([
      APPS.FLEET_MONITOR.ROLES.ADMIN,
      APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
    ]);
  }
}
